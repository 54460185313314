require("packs/filters");
require("packs/bookmarks");
require("packs/home_firebase");
require("packs/link_tracker");

import { setEvents } from "packs/bookmarks";

document.addEventListener("DOMContentLoaded", () => {
  // IntersectionObserver setup
  function observePageNumberDivs() {
    let observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          let pageNumber = entry.target.getAttribute("data-page-number");
          updateURLWithPageNumber(pageNumber);
        }
      });
    });

    document.querySelectorAll(".page-number-set").forEach((div) => {
      observer.observe(div);
    });
  }

  function updateURLWithPageNumber(pageNumber) {
    let params = new URLSearchParams(window.location.search);

    if (
      pageNumber === "1" ||
      pageNumber === undefined ||
      pageNumber.length === 0
    ) {
      params.delete("page");
    } else {
      params.set("page", pageNumber);
    }

    let newUrl = `${window.location.origin}${window.location.pathname}${
      params.toString() ? "?" + params.toString() : ""
    }`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  }

  function appendHiddenDiv(pageNumber) {
    let dealsList = document.querySelector(".deals-list");

    if (dealsList) {
      let newDiv = document.createElement("div");
      newDiv.classList.add("page-number-set");
      newDiv.setAttribute("data-page-number", pageNumber);
      dealsList.appendChild(newDiv);

      observePageNumberDivs();
    }
  }

  observePageNumberDivs();

  var loading = false;
  var page = getPageFromURL();
  var lastRequestTime = 0;
  const THROTTLE_DELAY = 500; // 1 second delay between requests
  appendHiddenDiv(page);

  let requestedPages = new Set();
  
  function loadMoreContent() {
    const now = Date.now();
    if (!loading && 
        isElementInViewport(document.getElementById("append_pagination")) && 
        !isFooterVisible() && 
        !requestedPages.has(page) &&
        now - lastRequestTime > THROTTLE_DELAY) {
      loading = true;
      lastRequestTime = now;
      document.getElementById("pagination-animation").classList.remove("hidden");
      var params = new URLSearchParams(window.location.search);
      var paramsObject = { page: page };
      for (const [key, value] of params) {
        paramsObject[key] = value;
      }
      $.ajax({
        url: "/hot",
        method: "GET",
        dataType: "script",
        headers: {
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
        data: paramsObject,
        success: function (response) {
          loading = false;
          document.getElementById("pagination-animation").classList.add("hidden");
          requestedPages.add(page);
          page += 1;
          appendHiddenDiv(page);
          setEvents();
          card_click();
          couponCodeCopy();
          setTimeout(recalculateHeight, 100);
        },
        error: function (error) {
          loading = false;
          document.getElementById("pagination-animation").classList.add("hidden");
        },
      });
    }
  }

  let scrollThrottleTimer;
  window.addEventListener("scroll", () => {
    if (scrollThrottleTimer) clearTimeout(scrollThrottleTimer);
    scrollThrottleTimer = setTimeout(loadMoreContent, 100);
  });
  
  // Check if more content needs to be loaded after the initial page load
  setTimeout(loadMoreContent, THROTTLE_DELAY);

  function isFooterVisible() {
    let footer = document.getElementById("footer-content");
    let rect = footer.getBoundingClientRect();
    let windowHeight = window.innerHeight || document.documentElement.clientHeight;
  
    let visiblePercentage = Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
    return visiblePercentage / rect.height >= 0.9;
  }

  function changeCardView(view) {
    let currentUrl = new URL(window.location.href);
    let displayViewParam = "display_view";
    let displayViewValue = view;
    currentUrl.searchParams.set(displayViewParam, displayViewValue);
    window.location.href = currentUrl;
  }
  document.querySelectorAll(".change-display-view").forEach((display) => {
    display.addEventListener("click", function () {
      changeCardView(this.dataset.view);
    });
  });

  document.querySelectorAll(".change-section").forEach((display) => {
    display.addEventListener("click", function () {
      changeSection(this.dataset.path);
    });
  });

  function changeSection(path) {
    let currentSearchParams = new URLSearchParams(window.location.search);
    let currentUrl = new URL(path, window.location.origin);
    
    currentSearchParams.forEach((value, key) => {
      if (currentSearchParams.getAll(key).length > 1) {
        currentUrl.searchParams.delete(key);
        currentSearchParams.getAll(key).forEach(val => {
          currentUrl.searchParams.append(key, val);
        });
      } else {
        currentUrl.searchParams.set(key, value);
      }
    });
  
    window.location.href = currentUrl.toString();
  }
});

function isElementInViewport(el) {
  if (!el || el.style.display === "none") {
    return false;
  }

  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function recalculateHeight() {
  $(document).height();
}

function getPageFromURL() {
  let urlParams = new URLSearchParams(window.location.search);
  let pageParam = urlParams.get('page');
  return pageParam ? (parseInt(pageParam, 10) + 1) : 2;
}