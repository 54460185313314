function bookmarkOrMute(id, link) {
  document.body.insertAdjacentHTML('beforeend', getModalContent("bookmarkOrMute", id, link));
  ["bookmark-link", "mute-link", "close-modal"].forEach(linkClass => {
    let links = document.querySelectorAll("." + linkClass);
    links.forEach(link => {
      link.addEventListener("click", () => {
        if (window.innerWidth < 768) {
          toggleModal(true, true);
        } else {
          toggleModal(false, true);
        }
      });
    });
  });
  let closeButton = document.querySelector(".close-modal");
  if (closeButton) {
    closeButton.addEventListener("click", () => {
      if (window.innerWidth < 768) {
        toggleModal(true, true);
      } else {
        toggleModal(false, true);
      }
    });
  }
  if (window.innerWidth < 768) {
    toggleModal(true, false);
  } else {
    toggleModal(false, false);
  }
}

function toggleModal(isMobile, isOpen) {
  let bookmarkModal = document.getElementById("bookmark-modal");
  let bookmarkDrawer = document.getElementById("bookmark-drawer");
  let target = isMobile ? bookmarkDrawer : bookmarkModal;
  let options = isMobile ? {
    placement: 'bottom',
    backdrop: true,
    bodyScrolling: false,
    edge: false,
    edgeOffset: '',
    backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30',
    onHide: () => {
      bookmarkDrawer.style.transform = "translateY(100%)";
        setTimeout(() => {
          bookmarkDrawer.style.display = "none";
          bookmarkDrawer.remove();
        }, 200);
    },
    onShow: () => {
        bookmarkDrawer.style.display = "block";
        bookmarkDrawer.style.transform = "translateY(0%)";
    },
    onToggle: () => {},
  } : {
    backdrop: 'dynamic',
    backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
    closable: true,
    onHide: () => {
      bookmarkModal.remove();
    },
    onShow: () => {},
    onToggle: () => {},
  };
	let instanceOptions = {
	  id: isMobile ? 'bookmark-drawer' : 'bookmark-modal',
	  override: true
	};
	let instance = isMobile ? new Drawer(target, options, instanceOptions) : new Modal(target, options, instanceOptions);
  if (isOpen) {
    instance.hide();
  } else {
    instance.show();
  }
}

function getModalContent(modal_type, id, link) {
  let content;
  if (modal_type === "bookmarkOrMute") {
    content = `<!-- Bookmark -->
      <div class="flex justify-between items-center mb-3">
        <p class="text-xl font-bold text-primary-text-light dark:text-secondary-text-dark">${I18n.t("manage_notifications")}</p>
        <svg class="w-6 h-6 text-primary-icon-light dark:text-primary-icon-dark close-modal cursor-pointer ${ (window.innerWidth < 768) ? 'hidden' : '' }" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 17.94 6M18 18 6.06 6"/>
        </svg>
      </div>
      <a href="/follow_topic?topic_id=${id}" class="bookmark-link" data-remote="true" data-method="post">
        <div class="common-box h-[160px] flex items-center justify-center mb-3">    
          <div class="w-[20%] flex justify-center items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="26.195" height="33.75" viewBox="0 0 26.195 33.75" class="text-primary fill-current">
            <path id="bookmark_FILL0_wght300_GRAD0_opsz24" d="M220-786.249v-30.108a3.516,3.516,0,0,1,1.058-2.585A3.516,3.516,0,0,1,223.643-820h18.91a3.516,3.516,0,0,1,2.585,1.058,3.516,3.516,0,0,1,1.058,2.585v30.108l-13.1-5.619Zm3.022-4.634,10.075-4.332,10.075,4.332v-25.474a.593.593,0,0,0-.194-.426.593.593,0,0,0-.426-.194h-18.91a.593.593,0,0,0-.426.194.593.593,0,0,0-.194.426Zm0-26.094h0Z" transform="translate(-220.001 819.999)"/>
          </svg>
          </div>
          <div class="w-[70%]">
            <p class="text-primary font-bold text-xl">${ I18n.t("bookmark") }</p>
            <div class="text-primary-text-light dark:text-secondary-text-dark text-base">${ I18n.t("bookmark_text") }</div>
          </div>
          <div class="w-[10%] flex justify-center items-center">
            <svg class="w-[18px] text-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7"/>
            </svg>
          </div>
        </div>
      </a>
      <!-- Mute -->
      <a href="/deals/${link}/mute" data-remote="true" class="mute-link" data-method="put">
        <div class="common-box h-[160px] flex items-center justify-center ">    
          <div class="w-[20%] flex justify-center items-center">
            <svg class="w-[35px] fill-current text-primary mr-2" xmlns="http://www.w3.org/2000/svg" width="36.611" height="38.198" viewBox="0 0 36.611 38.198">
              <path id="notifications_off_FILL0_wght300_GRAD0_opsz24" d="M86.164-828.254v-2.906h3.5v-14.457a11.286,11.286,0,0,1,.328-2.695,10.692,10.692,0,0,1,.984-2.551l2.2,2.2a8.147,8.147,0,0,0-.453,1.5,7.945,7.945,0,0,0-.151,1.547v14.457h14.1L81.387-856.37l2.042-2.042L118-823.843l-2.042,2.042-6.513-6.453Zm25.56-7.422-2.906-2.906v-7.035a7.826,7.826,0,0,0-2.379-5.743,7.826,7.826,0,0,0-5.743-2.379,8.183,8.183,0,0,0-2.762.471,7.2,7.2,0,0,0-2.388,1.414l-2.1-2.1a11.4,11.4,0,0,1,2.241-1.515,9.746,9.746,0,0,1,2.588-.9v-1.207a2.336,2.336,0,0,1,.706-1.715,2.332,2.332,0,0,1,1.714-.706,2.34,2.34,0,0,1,1.716.706,2.333,2.333,0,0,1,.708,1.715v1.207a10.463,10.463,0,0,1,6.2,3.813,10.813,10.813,0,0,1,2.4,6.94ZM99.621-838.191Zm1.07,15.377a3.366,3.366,0,0,1-2.473-1.029,3.376,3.376,0,0,1-1.026-2.474h7a3.369,3.369,0,0,1-1.03,2.476A3.381,3.381,0,0,1,100.691-822.815ZM102.181-845.218Z" transform="translate(-81.387 859.999)"/>
            </svg>
          </div>
          <div class="w-[70%]">
            <p class="text-primary font-bold text-xl">${ I18n.t("mute") }</p>
            <div class="text-primary-text-light dark:text-secondary-text-dark text-base">${ I18n.t("mute_topic_text") }</div>
          </div>
          <div class="w-[10%] flex justify-center items-center">
            <svg class="w-[18px] text-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7"/>
            </svg>
          </div>
        </div>
      </a>`
  } else if (modal_type === "removeBookmark") {
    content = `<!-- Bookmark -->
      <div class="flex justify-between items-center mb-3">
        <p class="text-xl font-bold text-primary-text-light dark:text-secondary-text-dark">${ I18n.t("manage_notifications") }</p>
        <svg class="w-6 h-6 text-custom-grey close-modal cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 17.94 6M18 18 6.06 6"/>
        </svg>
      </div>
      <a href="/unfollow_topic?topic_id=${id}" class="bookmark-link" data-remote="true" data-method="post">
        <div class="h-[160px] rounded-bradius10 border border-light-border dark:border-dark-border flex items-center justify-center mb-3">    
          <div class="w-[20%] flex justify-center items-center">
           <svg id="Layer_1" class="w-[26px] fill-current text-primary mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.25 19.97"><defs></defs><g id="bookmarks"><path id="Path_4661" d="M13.56,0H2.66C1.17.02-.02,1.24,0,2.73v15.62c0,.64.37,1.21.95,1.47.55.26,1.21.16,1.66-.26l5.1-4.53c.24-.19.58-.19.82,0l5.1,4.53c.45.41,1.11.51,1.66.26.58-.26.96-.84.95-1.47V2.73C16.27,1.24,15.07.02,13.58,0c0,0-.02,0-.03,0Z"/></g></svg>
          </div>
          <div class="w-[70%]">
            <p class="text-primary font-bold text-xl">${ I18n.t("remove_bookmark") }</p>
            <div class="text-primary-text-light dark:text-secondary-text-dark text-base">${ I18n.t("remove_bookmark_text") }</div>
          </div>
          <div class="w-[10%] flex justify-center items-center">
            <svg class="w-[18px] text-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7"/>
            </svg>
          </div>
        </div>
      </a>`

  } else if (modal_type === "unmutePost") {
    content = `<!-- Mute -->
      <div class="flex justify-between items-center mb-3">
        <p class="text-xl font-bold text-primary-text-light dark:text-secondary-text-dark">${ I18n.t("manage_notifications") }</p>
        <svg class="w-6 h-6 text-custom-grey close-modal cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 17.94 6M18 18 6.06 6"/>
        </svg>
      </div>
      <a href="/deals/${link}/mute?remove=1" data-remote="true" class="mute-link" data-method="put">
        <div class="h-[160px] rounded-bradius10 border border-light-border dark:border-dark-border flex items-center justify-center ">    
          <div class="w-[20%] flex justify-center items-center">
            <svg class="w-[35px] fill-current text-primary mr-2" xmlns="http://www.w3.org/2000/svg" width="36.611" height="38.198" viewBox="0 0 36.611 38.198">
              <path id="notifications_off_FILL0_wght300_GRAD0_opsz24" d="M86.164-828.254v-2.906h3.5v-14.457a11.286,11.286,0,0,1,.328-2.695,10.692,10.692,0,0,1,.984-2.551l2.2,2.2a8.147,8.147,0,0,0-.453,1.5,7.945,7.945,0,0,0-.151,1.547v14.457h14.1L81.387-856.37l2.042-2.042L118-823.843l-2.042,2.042-6.513-6.453Zm25.56-7.422-2.906-2.906v-7.035a7.826,7.826,0,0,0-2.379-5.743,7.826,7.826,0,0,0-5.743-2.379,8.183,8.183,0,0,0-2.762.471,7.2,7.2,0,0,0-2.388,1.414l-2.1-2.1a11.4,11.4,0,0,1,2.241-1.515,9.746,9.746,0,0,1,2.588-.9v-1.207a2.336,2.336,0,0,1,.706-1.715,2.332,2.332,0,0,1,1.714-.706,2.34,2.34,0,0,1,1.716.706,2.333,2.333,0,0,1,.708,1.715v1.207a10.463,10.463,0,0,1,6.2,3.813,10.813,10.813,0,0,1,2.4,6.94ZM99.621-838.191Zm1.07,15.377a3.366,3.366,0,0,1-2.473-1.029,3.376,3.376,0,0,1-1.026-2.474h7a3.369,3.369,0,0,1-1.03,2.476A3.381,3.381,0,0,1,100.691-822.815ZM102.181-845.218Z" transform="translate(-81.387 859.999)"/>

          </div>
          <div class="w-[70%]">
            <p class="text-primary font-bold text-xl">${ I18n.t("unmute_post") }</p>
            <div class="text-primary-text-light dark:text-secondary-text-dark text-base">${ I18n.t("unmute_text") }</div>
          </div>
          <div class="w-[10%] flex justify-center items-center">
            <svg class="w-[18px] text-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7"/>
            </svg>
          </div>
        </div>
      </a>`
  }

  let modal;

  if (window.innerWidth < 768) {
    modal = `<div id="bookmark-drawer" style="display:none" class="fixed p-[12px] bottom-0 left-0 right-0 z-40 w-full rounded-t-[20px] overflow-y-auto transition-transform bg-white dark:bg-background-dark translate-y-[100%] transition" tabindex="-1" aria-labelledby="drawer-bottom-label">
        ${content}
      </div>`;
  } else {
    modal = `<div id="bookmark-modal" tabindex="-1" class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div class="relative w-full max-w-md max-h-full">
          <!-- Modal content -->
          <div class="common-box relative shadow w-[450px] mx-auto p-[20px]">
            ${content}
          </div>
      </div>
    </div>`
  }

  return modal;
}

function removeBookmark(id) {
  document.body.insertAdjacentHTML('beforeend', getModalContent("removeBookmark", id, null));
  ["bookmark-link", "mute-link", "close-modal"].forEach(linkClass => {
    let container = ""
    if (window.innerWidth < 768)
      container = "#bookmark-drawer"
    else
      container = "#bookmark-modal"
    let link = document.querySelector(`${container} .${linkClass}`);
    if (link) {
      link.addEventListener("click", () => {
        if (window.innerWidth < 768) {
          toggleModal(true, true);
        } else {
          toggleModal(false, true);
        }
      });
    }
  });
  if (window.innerWidth < 768) {
    toggleModal(true, false);
  } else {
    toggleModal(false, false);
  }
}

function unmutePost(link) {
  document.body.insertAdjacentHTML('beforeend', getModalContent("unmutePost", null, link));
  ["bookmark-link", "mute-link", "close-modal"].forEach(linkClass => {
    let link = document.querySelector("." + linkClass);
    if (link) {
      link.addEventListener("click", () => {
        if (window.innerWidth < 768) {
          toggleModal(true, true);
        } else {
          toggleModal(false, true);
        }
      });
    }
  });
  if (window.innerWidth < 768) {
    toggleModal(true, false);
  } else {
    toggleModal(false, false);
  }
}

document.addEventListener("DOMContentLoaded", () => {
  setEvents();
  document.dispatchEvent(new Event('bookmarksLoaded'));
});

function setEvents() {
  let bookmark = document.querySelectorAll(".bookmark-or-mute");
  bookmark.forEach(element => {
    element.addEventListener("click", el => {
      let post = el.currentTarget;
      let post_id = post.dataset.post;
      let link = post.dataset.link;
      const currentUserBlank = element.dataset.currentUser === 'true';
      if (currentUserBlank) {
        openLogin();
      } else {
        bookmarkOrMute(post_id, link);
      }
    })
  });

  let remove_bookmark = document.querySelectorAll(".remove-bookmark");
  remove_bookmark.forEach(element => {
    element.addEventListener("click", el => {
      let post = el.currentTarget;
      let post_id = post.dataset.post;
      removeBookmark(post_id);
    })
  });
  
  let unmute_post = document.querySelectorAll(".unmute-post");
  unmute_post.forEach(element => {
    element.addEventListener("click", el => {
      let post = el.currentTarget;
      let link = post.dataset.link;
      unmutePost(link);
    })
  });
}

window.setEvents = setEvents;

export { setEvents };